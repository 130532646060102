import $ from 'jquery';
import 'what-input';
import './lib/jquery.mask.min.js';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

$(document).foundation();

$(document).ready(function(){

  var clientsSwiper = new Swiper('.section--carousel__clients .swiper-container', {
    slidesPerView: 6,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      800: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 0
      },
    }
  });
  var awardsSwiper = new Swiper('.section--carousel__awards .swiper-container', {
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      800: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 0
      },
    }
  });


  // services Animation
  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return (elementBottom + 200) > viewportTop && (elementTop + 200) < viewportBottom;
  }; // is Element in Viewport?

  $(window).on('resize scroll', function() {
    $('.section--services__block').each(function() {
      var activeService = $(this).attr('id');
      if ($(this).isInViewport()) {
        $('#animate-' + activeService).addClass('is-active');
      } else { }
    });
  });


  // Phone Number Mask Input
  var PhoneMaskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  },
  phoneOptions = {
    onKeyPress: function(val, e, field, options) {
      field.mask(PhoneMaskBehavior.apply({}, arguments), options);
    }
  };
  $('#phone').mask(PhoneMaskBehavior, phoneOptions);

  
  // Submit Form

  // $('#contact-form').submit(function(event) {
  //   event.preventDefault();
  
  //   $('.resultado-form').addClass('hide');
  
  //   var formData = $('#contact-form').serialize();

  //   $.ajax({
  //     type: 'POST',
  //     url: $('#contact-form').attr('action'),
  //     data: formData
  //   })
  //   .done(function(response) {
  //     console.log(response);
  //     $('.dialog-box.success').removeClass('hide');
  //     $('#contact-form').reset();
  //   })
  //   .fail(function (error) {
  //     console.log(error);
  //     $('.dialog-box.error').removeAttr('style');
  //   });
  
  // }); // form submit

  let $formClient = $('form#contact-form');

  $formClient
    .on('formvalid.zf.abide', function (ev, frm) {
      // console.log("Form id " + frm.attr('id') + " is valid");

      var formData = $formClient.serialize();

      $.ajax({
        type: 'POST',
        url: $('#contact-form').attr('action'),
        data: formData
      })
      .done(function(response) {
        console.log(response);
        $('.dialog-box.success').removeClass('hide');
        // $('#contact-form').reset();
      })
      .fail(function (error) {
        console.log(error);
        $('.dialog-box.error').removeAttr('style');
      });
    })
    .on('forminvalid.zf.abide', function(ev, frm) {
      // console.log("Form id " + ev.target.id + " is invalid");
      $('.resultado-form', ev.target).addClass('hide');
      showError(ev.target);
    })
    .on('submit', function(ev) {
      ev.preventDefault();
      // console.log('Submit for form id ' + ev.target.id + ' intercepted');
    });

    let showSuccess = function(form) {
      $('.dialog-box.success').removeClass('hide');
      form[0].reset();
    }
    
    let showError = function(form, message) {
      if(message) {
        console.log(error.message);
      } else {
        $('.dialog-box.error').removeAttr('style');
      }
    }

}); // $(document).ready